/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environments';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LessonPlanFetchResult } from '../data-models/lesson-plan';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  API_URL = `${environment.apiURL}`

  constructor(
    private http: HttpClient,
  ) { }

  /**
   * Change user preference.
   * @param value
   * @param id
   * @returns
   */
  preferenceChanging(value: any, id: any) {
    const config = { headers: new HttpHeaders().set('Content-Type', 'application/json') }
    return this.http.post(`${this.API_URL}/user/preference/${id}`, value, config);
  }

  /**
   * Get users educational info.
   * @param user_id
   * @returns
   */
  getEducational(user_id: any) {
    const params = new HttpParams().append('id', user_id)
      .append('profileid', user_id)
      .append('lastid', "")
    return this.http.get(`${this.API_URL}/user/get-education`, { params: params });
  }

  createEducation(value: any) {
    const httpParams = new HttpParams()
      .append('id', value.id)
      .append("university_name", value.university_name)
      .append("course_name", value.course_name)
      .append("year_in", value.year_in)
      .append("year_out", value.year_out)
    return this.http.put(this.API_URL + `/user/add-education`, httpParams);
  }

  deleteEducations(id: any, education_id: any) {
    const httpParams = new HttpParams()
      .append('id', id)
      .append("education_id", education_id)
    return this.http.put(this.API_URL + `/user/delete-education`, httpParams);
  }

  editDobGender(value: any, id: any) {
    const httpParams = new HttpParams()
      .append('gender', value.gender)
      .append("dob", value.dob)
    return this.http.put(this.API_URL + `/users/${id}/dob`, httpParams);
  }

  editPersonal(data: any, id: any) {
    const config = { headers: new HttpHeaders().set('Content-Type', 'application/json') }
    return this.http.put(this.API_URL + `/users-new/${id}`, data, config);
  }

  /**
 * Get all sports from tha db.
 *
 * @returns a array of sports in dartle.
 */
  getSportList() {
    return this.http.get(this.API_URL + `/users/register/fetchData/sportslist`)
  }


  /**
   * Get all country from tha db
   *
   * @returns a array of country in dartle.
   */
  getCountryList() {
    return this.http.get(this.API_URL + `/users/register/fetchData/countrylist`)
  }

  /**
   * Get parent_info from tha db
   *
   * @returns a array.
   */
  // getParent(id:any) {
  //   return this.http.get(this.API_URL + `/member-parent/${id}`)
  // }



  /**
   * Get EULA of the platform
   *
   * @returns
   */
  getEula() {
    const params = new HttpParams().append('setting', "Eula")
    return this.http.get(this.API_URL + `/settings`, { params: params })
  }

  /**
   * Get default lesson plam template
   *
   * @returns a template of dartle lesson plan.
   */
  getDefaultLessonPlan() {
    return this.http.get<LessonPlanFetchResult>(this.API_URL + `/lesson`)
  }


  /**
   * Get user Achievement from the Database.
   *
   * @param id ObjectID : UserId of the user.
   * @returns
   */
  fetchUserAchievement(id: any) {
    const params = new HttpParams().append('id', id)
      .append('profileid', id)
    return this.http.get(this.API_URL + `/user/get-achievements`, { params: params })
  }


  editContact(id: any, data: any) {
    const params = new HttpParams().append('id', id)
      .append('profileid', id)
    const config = { headers: new HttpHeaders().set('Content-Type', 'application/json'), params: params }
    return this.http.post(`${this.API_URL}/user/get-contact`, data, config)
  }




  /**
   * Get user homework data from the database.
   *
   * @param id ObjectID : UserId of the Academy.
   * @param user ObjectID : UserId of the User.
   * @returns
   */
  fetchHomework(id: any, user: any) {
    return this.http.get(this.API_URL + `/lesson-attendance/${id}/${user}`)
  }

  /**
   * Get user homework data from the database.
   *
   * @param id ObjectID : UserId of the Academy.
   * @param user ObjectID : UserId of the User.
   * @returns
   */
  fetchProfileHomework(id: any, user: any) {
    return this.http.get(this.API_URL + `/profile-homework/${id}/${user}`)
  }

  /**
   * Get user pd data from the database.
   *
   * @param id ObjectID : UserId of the Academy.
   * @param user ObjectID : UserId of the User.
   * @returns
   */
  fetchProfilePdSessions(id: any, user: any) {
    return this.http.get(this.API_URL + `/profile-pd/${id}/${user}`)
  }




  /**
   * Get User Feeds from the database.
   *
   * @param id id ObjectID : UserId of the Student.
   * @returns
   */
  fetchFeeds(id: string, profileid: string) {
    const params = new HttpParams().append('id', id)
      .append('profileid', profileid)
      .append('lastid', "")
    return this.http.get(this.API_URL + `/feed/get-feeds`, { params: params })
  }




  /**
   * Get Faqs from database.
   *
   * @returns
   */
  getFaqs() {
    return this.http.get<LessonPlanFetchResult>(this.API_URL + `/faq`)
  }

  /**
   * Fetch the shared access signature token to upload file to the Azure blobs.
   *
   * @param storage
   * @returns
   */
  getUploadTokenAzure(storage: string): Observable<any> {
    const params = new HttpParams().append('storage', storage)
    return this.http.get(`${this.API_URL}/azure/upload/token`, { params: params })
  }



  sentEmailService(data: any) {
    const config = { headers: new HttpHeaders().set('Content-Type', 'application/json') }
    return this.http.post(this.API_URL + `/email`, data, config);
  }

  editBios(id: any, data: any) {
    const config = { headers: new HttpHeaders().set('Content-Type', 'application/json') }
    return this.http.post(`${this.API_URL}/user/profile/${id}`, data, config);
  }
}
